<template>
  <div class="order-item" >
    <van-panel :title="item.customerPlateNum" :status="item.orderStatusStr" :desc="`${item.customerName} ${item.customerMobile}`">
      <van-cell title="订单类型" :value="item.orderCategory"/>
      <van-cell title="预约时间" :value="item.planTime"/>
      <van-cell title="预约网点" :value="item.storeName"/>
      <van-cell title="网点地址" :value="item.address"/>
      <van-cell title="支付状态" :value="item.payStatusStr"/>
      <div class="order-item__actions" v-if="item.orderStatus <= 3">
        <van-button size="small" type="primary"  @click="openLocation">导航去</van-button>
        <van-button size="small" type="danger"  @click="cancelOrder">取消</van-button>
      </div>
    </van-panel>


  </div>
</template>

<script>
import {Panel, Button, Toast, Notify, Cell, Dialog} from 'vant';

  export default {
    name: "orderItem",
    props: { item:Object},
    components: {
      vanButton:Button,
      vanCell:Cell,
      vanPanel:Panel,
    },
    computed:{

    },
    methods:{
      toDetail(){
        const orderNo = this.item.orderNo;
        this.$router.push(`/order/${orderNo}`);
      },
      openLocation(){
        const _this = this;
        this.$wechat.ready(() => {
          const param = {
            latitude: parseFloat(_this.item.lat+""), // 纬度，浮点数，范围为90 ~ -90
            longitude: parseFloat(_this.item.lng+""), // 经度，浮点数，范围为180 ~ -180。
            name: _this.item.storeName, // 位置名
            address: _this.item.address, // 地址详情说明
            scale: 12
          };
          _this.$wechat.openLocation(param);
        });

      },
      cancelOrder(){
        const _that = this;
        Dialog.confirm({
          title: '提示',
          message: '您确定要取消订单么？',
        })
          .then(() => {
            // on confirm
            const orderNo = _that.item.orderNo;
            _that.$http.put(`/customer/order/${orderNo}/cancel`, {
              remark:'用户主动取消',
              orderNo:orderNo,
            }).then(resp => {
              if(resp.success){
                Toast("取消成功");
                window.location.reload();
              } else {
                Notify(resp.message);
              }
            })
          })
          .catch(() => {
            // on cancel
          });

      }
    }
  }
</script>

<style  lang="less">
  @import "../../assets/less/variable";


  .order-item {
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-size: @primary-font-size;
    background-color: @base-block__background;


    &__top {
      display: flex;
      justify-content: space-between;
      height: 30px;
      align-items: center;
    }

    &__outlet-cover{
      width: 110px;
      margin-right:10px;
    }

    &__middle {
      display: flex;
      height: 90px;
    }

    &__actions {
      margin-top:@padding;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top:1px solid #EAEAEA;
      .van-button + .van-button{
        margin-left:@padding
      }
    }

    &__info{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-item{
        line-height:1.2;
        font-size: 14px;
      }
    }
  }

  .order-item ~ .order-item{
    margin-top:10px;
  }
</style>
