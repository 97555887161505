<template>
  <div class="page full-height">
    <van-nav-bar
        title="我的订单"
        left-text="返回"
        @click-left="() => {this.$router.go(-1)}"
        left-arrow
    />
    <van-tabs v-model="active" :swipe-threshold="5" @change="tabChange" color="#07c160">
      <van-tab title="全部" :name="''">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
      <van-tab title="已接单" :name="3">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
      <van-tab title="已完成" :name="4">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
      <van-tab title="已取消" :name="5">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {Tabs, Tab, NavBar} from 'vant';
import orderItem from '../../components/order/OrderItem';
import {mapState} from "vuex";
import api from "@/service/api";
import router from "@/router";
import {isIos} from "@/utils/weChatUtil";
export default {
  name: "orderList",
  components: {
    vanTabs: Tabs,
    vanTab: Tab,
    orderItem,
    vanNavBar: NavBar,
  },
  data() {
    return {
      active: 0,
      orderList: {
        loading: false,
        finished: false,
        data: [],
        page: 1,
        size: 10
      }
    }
  },
  computed: {
    ...mapState({
      fromCompany: state => state.app.fromCompany
    })
  },
  methods: {
    tabChange() {
      this.orderList = {
        loading: false,
        finished: false,
        data: [],
        page: 1,
        size: 10
      };
      this.loadOrderList()
    },
    adapterOrderStatus() {
      //根据
      switch (this.active) {

        case 3:
          return {status: 3};
        case 4:
          return {status: 4};
        case 5:
          return {status: 5};
        default:
          return null
      }
    },
    loadOrderList() {
      const param = {
        params: Object.assign(
            {
              page: this.orderList.page,
              size: this.orderList.size,
            },
            this.adapterOrderStatus()
        )
      };

      this.$http.get("/customer/order/list", param).then(resp => {
        if (resp.success) {
          this.orderList.finished = resp.data.list.length < this.orderList.size;
          if (!this.orderList.finished) {
            this.orderList.page++;
          }
          // eslint-disable-next-line no-debugger

          this.orderList.data = this.orderList.data.concat(resp.data.list);
          console.log(this.orderList.data);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.orderList.loading = false;
      })
    }
  },
  mounted() {
    this.loadOrderList();
    let url = location.href.split('#')[0]
    if(isIos()){
      url =  window.location.href;
    }
    api.post("/wechat/jsapi", url, {headers: {'Content-Type': 'text/plain'}})
        .then(resp => {
          if (resp.success) {
            const sign = resp.data;
            router.app.$wechat.config({
              debug: false,
              appId: sign.appId,
              timestamp: sign.timestamp,
              nonceStr: sign.nonceStr,
              signature: sign.signature,
              jsApiList: [
                'getLocation', 'openLocation', 'onMenuShareTimeline',
                'onMenuShareAppMessage', 'chooseWXPay', 'showMenuItems', 'hideMenuItems',
                'updateAppMessageShareData', 'updateTimelineShareData','hideAllNonBaseMenuItem'
              ]
            });
          }
        }).then(() => {
      this.$wechat.ready(() => {
        this.$wechat.hideAllNonBaseMenuItem();
      });
    })

  }
}
</script>

<style lang="less">

</style>
